import { FC, ReactNode } from 'react';

import styles from './CountStatus.module.scss';

import { JitText } from 'components/JitText/JitText';

interface Props {
  count: ReactNode;
  mutedText: string;
}

export const CountStatus: FC<Props> = ({ count, mutedText }) => {
  const shouldDisplayRawCount = typeof count === 'object' || typeof count === 'boolean';
  return (
    <div className={styles.wrapper}>
      {shouldDisplayRawCount ? count : <JitText bold data-testid='count' muted size='l' text={count} />}

      <JitText className={styles.description} data-testid='text' fontFamily='Inter, serif' muted text={mutedText} textAlign='center' />
    </div>
  );
};
