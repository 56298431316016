/* eslint-disable max-statements */
import { paragon } from '@useparagon/connect';
import React, { useCallback, useMemo, useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { QuickstartGuideData, CheckStatus } from '../../types';

import styles from './ConfigureCloudIntegration.module.scss';

import { IconButtonsGrid } from 'components/IconButtonsGrid/IconButtonsGrid';
import { useConfigurationsContext } from 'context/ConfigurationsContext/ConfigurationsContext';
import { useDialogsContext } from 'context/DialogsContext/DialogsContext';
import { useIntegrationsContext } from 'context/IntegrationsContext';
import { useParagonAuth } from 'context/IntegrationsContext/hooks/useParagonAuth';
import { IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';
import { useTenantContext } from 'context/TenantContext';
import { CONFIGURE_CLOUD_INTEGRATION_CHECK_SLUG } from 'context/TenantContext/constant';
import { useUpdateCheckStatus } from 'context/TenantContext/tenantHooks/useUpdateCheckStatus';
import { Dialogs, Vendor } from 'types/enums';
import { Queries } from 'types/enums/Queries';
import { IntegrationStatus } from 'types/interfaces/Integrations';
import { isVendorCloudProvider } from 'utils/functions/matchVendorToAssetType';
import { useDetailsByVendor } from 'utils/hooks/useDetailsByVendor';

const getVendorFromString = (vendorString: string): Vendor | undefined => Object.values(Vendor).find((v) => v === vendorString) as Vendor | undefined;

export const ConfigureCloudIntegration: React.FC = () => {
  const { integrations, isLoading: isIntegrationsLoading } = useIntegrationsContext();
  const { installations } = useTenantContext();
  const { configurations } = useConfigurationsContext();
  const { getIconByVendor, getVendorDisplayName } = useDetailsByVendor();
  const { isLoading: isParagonAuthLoading } = useParagonAuth();
  const { showDialog } = useDialogsContext();
  const queryClient = useQueryClient();
  const { updateCheckStatus } = useUpdateCheckStatus();

  const showAWSDialog = useCallback(() => {
    showDialog({
      type: Dialogs.Integrations,
      props: {
        vendor: Vendor.AWS,
        integration: configurations[Vendor.AWS.toString()],
      },
    });
  }, [showDialog, configurations]);

  const successfulCloudIntegration = useMemo(
    () => integrations?.find((integration) => integration.display?.categoryKey === IntegrationVendorType.cloud
      && integration.status === IntegrationStatus.SUCCESS),
    [integrations],
  );

  const cloudProviderInstallation = useMemo(
    () => installations?.find((installation) => isVendorCloudProvider(getVendorFromString(installation.vendor))),
    [installations],
  );

  const activeCloudVendor = useMemo(() => {
    if (successfulCloudIntegration) {
      return successfulCloudIntegration.vendor;
    }
    if (cloudProviderInstallation) {
      return getVendorFromString(cloudProviderInstallation.vendor);
    }
    return null;
  }, [successfulCloudIntegration, cloudProviderInstallation]);

  const awsIntegration = useMemo(() => ({
    key: Vendor.AWS,
    icon: getIconByVendor(Vendor.AWS, undefined, true),
    onClick: showAWSDialog,
    isSelected: activeCloudVendor === Vendor.AWS,
    isDisabled: !!(activeCloudVendor && activeCloudVendor !== Vendor.AWS),
    text: getVendorDisplayName(Vendor.AWS),
  }), [getIconByVendor, showAWSDialog, activeCloudVendor, getVendorDisplayName]);

  const cloudIntegrations = useMemo(
    () => integrations?.filter((integration) => integration.display?.categoryKey === IntegrationVendorType.cloud).map((integration) => ({
      key: integration.vendor,
      icon: getIconByVendor(integration.vendor, undefined, true),
      onClick: () => paragon.connect(integration.vendor, {
        onInstall: () => updateCheckStatus(CONFIGURE_CLOUD_INTEGRATION_CHECK_SLUG),
      }),
      isSelected: activeCloudVendor === integration.vendor,
      isDisabled: !!(activeCloudVendor && activeCloudVendor !== integration.vendor),
      text: getVendorDisplayName(integration.vendor),
    })) || [],
    [integrations, getIconByVendor, activeCloudVendor, getVendorDisplayName, updateCheckStatus],
  );

  const items = useMemo(() => [awsIntegration, ...cloudIntegrations], [awsIntegration, cloudIntegrations]);

  useEffect(() => {
    if (cloudProviderInstallation) {
      const currentData = queryClient.getQueryData<QuickstartGuideData>([Queries.QuickstartChecks]);
      const checkNeedsUpdate = currentData?.checks.some(
        (check) => check.checkSlug === CONFIGURE_CLOUD_INTEGRATION_CHECK_SLUG && check.status !== CheckStatus.COMPLETED,
      );

      if (checkNeedsUpdate) {
        updateCheckStatus(CONFIGURE_CLOUD_INTEGRATION_CHECK_SLUG);
      }
    }
  }, [cloudProviderInstallation, queryClient, updateCheckStatus]);

  return (
    <div className={styles.root}>
      <IconButtonsGrid
        isLoading={isParagonAuthLoading || isIntegrationsLoading}
        items={items}
      />
    </div>
  );
};
