import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { FC } from 'react';
import { useMutation } from 'react-query';

import styles from './SkipButton.module.scss';

import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import { useUpdateCheckStatus } from 'context/TenantContext/tenantHooks/useUpdateCheckStatus';
import { UICheck, Check, CheckStatus } from 'pages/QuickStartGuide/types';
import { logError } from 'services/logger/logger';
import { useTenantService } from 'services/TenantService/useTenantService';

interface Props {
  nonRequiredPendingChecks?: (UICheck & Check)[];
  nextSectionDisplayName: string;
}

export const SkipButton: FC<Props> = ({ nonRequiredPendingChecks, nextSectionDisplayName }) => {
  const { updateCheckStatus } = useUpdateCheckStatus();
  const { updateCheckStatus: updateCheckStatusService } = useTenantService();

  const { mutate: skipChecks, isLoading } = useMutation(
    async () => {
      if (!nonRequiredPendingChecks) return;

      const updatePromises = nonRequiredPendingChecks.map((check) => updateCheckStatusService({
        checkSlug: check.checkSlug,
        status: CheckStatus.SKIPPED,
      }));

      await Promise.all(updatePromises);
    },
    {
      onSuccess: () => {
        if (!nonRequiredPendingChecks) return;
        nonRequiredPendingChecks.forEach((check) => {
          updateCheckStatus(check.checkSlug, CheckStatus.SKIPPED);
        });
      },
      onError: (error) => {
        logError(`Failed to update check statuses: ${error}`);
      },
    },
  );

  return (
    <JitGradientButton
      className={styles.skipButton}
      data-testid='quick-guide-section-skip-button'
      disabled={isLoading}
      isLoading={isLoading}
      onClick={() => skipChecks()}
      showChildrenWhileLoading
    >
      <div className={styles.skipButtonContainer}>
        <JitText size='s' text={nextSectionDisplayName} />

        <ArrowForwardIcon className={styles.arrowIcon} sx={{ fontSize: 12 }} />
      </div>
    </JitGradientButton>
  );
};
