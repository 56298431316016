
import { groupBy } from 'lodash';
import { useMemo } from 'react';

import { calcPriorityScoreColor } from 'pages/FindingsPage/components/PriorityBox/utils';
import { useFindingsCount as useFindingsCountHook } from 'services/FindingsService/hooks';

export const useFindingsCount = () => {
  const { data: priorityScores, isFetching: isPriorityScoresLoading } = useFindingsCountHook({ params: { group_by: 'priority_score',
    filters: { ignored: false } } });

  const priorityScoresCount = useMemo(() => {
    if (!Array.isArray(priorityScores)) {
      return [];
    }

    const removedZero = priorityScores.filter((score) => score.key !== '0');
    const groupedByColor = groupBy(removedZero, (item) => calcPriorityScoreColor(Number(item.key)));

    return Object.values(groupedByColor).map((group) => ({
      key: group[0].key,
      count: group.reduce((acc, item) => acc + item.count, 0),
    }));
  }, [priorityScores]);

  const { data: rawCount, isFetching: isRawCountLoading } = useFindingsCountHook({
    params: {
      filters: { ignored: false },
    },
  }, { enabled: Boolean(priorityScores) && priorityScoresCount.length === 0 });

  const isLoading = isPriorityScoresLoading || isRawCountLoading;

  return useMemo(() => {
    if (!rawCount || Array.isArray(rawCount) || (priorityScoresCount.length)) {
      return {
        counts: priorityScoresCount,
        contextEngineFinished: true,
        isLoading } as const;
    }

    return {
      counts: rawCount.count,
      contextEngineFinished: false,
      isLoading } as const;
  }, [priorityScoresCount, rawCount, isLoading]);
};
