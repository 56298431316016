import { useQuery } from 'react-query';

import { fetchFindingsCount } from './FindingsService';

import { Queries } from 'types/enums/Queries';
import { ITableQueryParams } from 'types/interfaces';

interface FindingsCountProps {
  params: ITableQueryParams
  backlog?: boolean | null
}
export const useFindingsCount = (props: FindingsCountProps, opts?: { enabled?: boolean }) => useQuery({
  queryFn: () => fetchFindingsCount(props.params, props.backlog),
  queryKey: [Queries.Findings, 'count', props],
  ...opts,
});

