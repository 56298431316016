import {
  IntegrateSlackButton,
  CloudIntegrationStatus,
  ConfigureCloudIntegration,
  ManageTeamsButton,
  SCMIntegrationTask,
  SecurityPlansProgress,
  ManageTeamsStatus,
  SlackStatus,
  SecurityPlanStatus,
  ViewFindingsButton,
  ViewFindingsStatus,
} from '../components';

export const mainComponentKeyToComponentMap = {
  scmIntegrationMain: SCMIntegrationTask,
  integrateSlackMain: IntegrateSlackButton,
  configureCloudIntegrationMain: ConfigureCloudIntegration,
  configureTeamsMain: ManageTeamsButton,
  securityPlansProgressMain: SecurityPlansProgress,
  viewFindingsMain: ViewFindingsButton,
};

export const statusComponentKeyToComponentMap = {
  exampleCheck1Status: CloudIntegrationStatus,
  manageTeamsStatus: ManageTeamsStatus,
  configureCloudIntegrationStatus: CloudIntegrationStatus,
  configureSlackStatus: SlackStatus,
  planProgress: SecurityPlanStatus,
  viewFindingsStatus: ViewFindingsStatus,
};
