import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { QuickstartGuideData, CheckStatus } from 'pages/QuickStartGuide/types';
import { Queries } from 'types/enums/Queries';

export const useUpdateCheckStatus = () => {
  const queryClient = useQueryClient();

  const updateCheckStatus = useCallback((
    checkSlug: string,
    status: CheckStatus = CheckStatus.COMPLETED,
  ) => {
    queryClient.setQueryData<QuickstartGuideData | undefined>(
      [Queries.QuickstartChecks],
      (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          checks: oldData.checks.map((check) => (
            check.checkSlug === checkSlug
              ? { ...check,
                status }
              : check
          )),
        };
      },
    );
  }, [queryClient]);

  return { updateCheckStatus };
};
